import React, {useEffect} from "react"
import styled from "styled-components";
import Layout from "../components/layout"
import SEO from "../components/seo"
import COLORS from "../styles/colors";
import ExperienceJson from "./experience.json";
import KidsImage from "./../images/101.jpg";

import Image1 from "./../images/florida-council-conference.png"
import Image2 from "./../images/florida-social-studies-conference.png"
import Image3 from "./../images/georgia-social-studies-conference.png"
import Image4 from "./../images/michigan-conference.png"
import Image5 from "./../images/minnesota-conference.png"
import Image7 from "./../images/north-carolina-conference.png"
import Image9 from "./../images/nyscss-conference.png"
import Image10 from "./../images/south-carolina-conference.png"
import Image11 from "./../images/stat-conference.png"
import Image12 from "./../images/tekscon.png"
import Image13 from "./../images/tennesee-conference.png"
import Image14 from "./../images/texas-bilingual-conference.png"
import Image15 from "./../images/texas-conference.png"
import Image16 from "./../images/tssa-conference.png"
import Image17 from "./../images/colorado-conference.png"
import Image18 from "./../images/msssa-conference.png"
import Image19 from "./../images/rrvb-conference.png";


const State = styled.div`
    max-width: 1200px;
    margin: 0 auto 2rem;
    & > div {
        column-count: ${props => props.state === "Texas" ? 5 : 3};
        column-gap: 2rem;

        @media screen and (max-width: 1000px) {
            column-count: ${props => props.state === "Texas" ? 4 : 2};
        }
        @media screen and (max-width: 768px) {
            column-count: ${props => props.state === "Texas" ? 2 : 1};
        }
    }
`;

const ConferenceWrapper = styled.div``;

const Region = styled.h3`
    color: ${COLORS.primaryColor};
    margin-bottom: 0.5rem;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-size: 1rem;
    font-weight: 300;
`;

const District = styled.div`
    font-weight: 300;
`;

const StateTitle = styled.h1`
    margin-bottom: 0;
`;

const StateWrapper = styled.div`
    &:nth-child(even) {
        background-color: #fafafa;
    }
`;

const StateContainer = styled.div`
    max-width: 1200px;
    margin: 0 auto;
    padding: 1rem;
`;

const Conferences = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
`;

const Conference = styled.div`
    width: 5rem;
    margin: 1rem;
    img {
        display: block;
        height: auto;
        max-width: 100%;
    }
`;

const Intro = styled.div`
      background: linear-gradient(
      rgba(53, 110, 216, 0.8), 
      rgba(53, 110, 216, 0.8)
    ),
    url(${KidsImage});
    background-position: center center;
    background-size: cover;
    color: #fff;
    padding: 2rem 0;
    text-align: center;

    div {
        max-width: 1200px;
        margin: 0 auto;
        padding: 0 1rem;
    }

    h1 {
        font-weight: 300;
    }

    p {
        font-weight: 300;
        max-width: 800px;
        margin: 0 auto 1rem;
    }
`;


const Experience = ({location}) => {
    useEffect(() => {
        let {state} = location && location.state;
        if (state) {
            if (state !== "Texas" && state !== "Florida" && state !== "Georgia") state = "various";
            const element = document.getElementById(state);
            setTimeout(() => {
                window.scrollTo({
                    top: element ? (element.offsetTop - 155) : 0
                });
            }, 100);
        }
    })

    return (
        <Layout>
            <SEO title="Experience" />
            <Intro>
                <div>
                <h1>Our Experience Tells Our Story.</h1>
                <p>We have been all over the U.S. providing quality workshops and education. Find your state to get a glimpse of who we have worked with in the past.</p>
                </div>
            </Intro>
            {ExperienceJson.map(item => (<>
                <StateWrapper id={item.state} key={item.state}>
                    <StateContainer>
                        <StateTitle>{item.state}</StateTitle>
                        <State state={item.state}>
                            <Region>Districts</Region>
                            <div>
                                {item.districts.map(district => <District>{district}</District>)}
                            </div>
                        </State>
                        {item.state === "Florida" ?
                            <ConferenceWrapper><Region>Conferences</Region>
                                <Conferences>
                                    <Conference style={{width: "9rem"}}><img src={Image1} alt="conference" /></Conference>
                                    <Conference><img src={Image2} alt="conference" /></Conference>
                                </Conferences></ConferenceWrapper> : ""
                        }
                        {item.state === "Georgia" ?
                            <ConferenceWrapper><Region>Conferences</Region>
                                <Conferences>
                                    <Conference><img src={Image3} alt="conference" /></Conference>
                                </Conferences></ConferenceWrapper> : ""
                        }
                        {item.state === "Michigan" ?
                            <ConferenceWrapper><Region>Conferences</Region>
                                <Conferences>
                                    <Conference style={{width: "6rem"}}><img src={Image4} alt="conference" /></Conference>
                                </Conferences></ConferenceWrapper> : ""
                        }
                        {item.state === "Minnesota" ?
                            <ConferenceWrapper><Region>Conferences</Region>
                                <Conferences>
                                    <Conference style={{width: "3.5rem"}}><img src={Image5} alt="conference" /></Conference>
                                </Conferences></ConferenceWrapper> : ""
                        }
                        {item.state === "North Carolina" ?
                            <ConferenceWrapper><Region>Conferences</Region>
                                <Conferences>
                                    <Conference><img src={Image7} alt="conference" /></Conference>
                                </Conferences></ConferenceWrapper> : ""
                        }
                        {item.state === "New York" ?
                            <ConferenceWrapper><Region>Conferences</Region>
                                <Conferences>
                                    <Conference style={{width: "10rem"}}><img src={Image9} alt="conference" /></Conference>
                                </Conferences></ConferenceWrapper> : ""
                        }
                        {item.state === "South Carolina" ?
                            <ConferenceWrapper><Region>Conferences</Region>
                                <Conferences>
                                    <Conference><img src={Image10} alt="conference" /></Conference>
                                </Conferences></ConferenceWrapper> : ""
                        }
                        {item.state === "Texas" ?
                            <ConferenceWrapper><Region>Conferences</Region>
                                <Conferences>
                                    <Conference><img src={Image12} alt="conference" /></Conference>
                                    <Conference><img src={Image14} alt="conference" /></Conference>
                                    <Conference><img src={Image15} alt="conference" /></Conference>
                                    <Conference><img src={Image16} alt="conference" /></Conference>
                                    <Conference style={{width: "14rem"}}><img src={Image11} alt="conference" /></Conference>
                                </Conferences></ConferenceWrapper> : ""
                        }
                        {item.state === "Tennesee" ?
                            <ConferenceWrapper><Region>Conferences</Region>
                                <Conferences>
                                    <Conference style={{width: "7rem"}}><img src={Image13} alt="conference" /></Conference>
                                </Conferences></ConferenceWrapper> : ""
                        }
                        {item.state === "Colorado" ?
                            <ConferenceWrapper><Region>Conferences</Region>
                                <Conferences>
                                    <Conference style={{width: "9rem"}}><img src={Image17} alt="conference" /></Conference>
                                </Conferences></ConferenceWrapper> : ""
                        }
                        {item.state === "Mississippi" ?
                            <ConferenceWrapper><Region>Conferences</Region>
                                <Conferences>
                                    <Conference><img src={Image18} alt="conference" /></Conference>
                                </Conferences></ConferenceWrapper> : ""
                        }
                        {item.state === "Rhode Island" ?
                            <ConferenceWrapper><Region>Conferences</Region>
                                <Conferences>
                                    <Conference style={{width: "6rem"}}><img src={Image19} alt="conference" /></Conference>
                                </Conferences></ConferenceWrapper> : ""
                        }
                    </StateContainer>
                </StateWrapper>
            </>
            ))}
        </Layout >
    )
}


export default Experience


